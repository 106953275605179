import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import LogoWhite from "~images/logo2150.png";
import LogoWhite2X from "~images/EatCo-Logo-white.png";
import picture1 from "~images/startup.png";

export default class Footer extends Component {

	componentDidMount() {
		// To top
		const toTop = document.querySelector('.to-top');

		// Scroll
		window.addEventListener('scroll', function() {
			const mainMenu = document.querySelector('.main-menu-area');
			const pos = window.scrollY;
	
			if (mainMenu) {
				if (pos >= 100) {
					mainMenu.classList.add('fixed-menu', 'animate', 'slideInDown');
				} else {
					mainMenu.classList.remove('fixed-menu', 'animate', 'slideInDown');
				}
			}
	
			// Scroll to top button
			if (pos >= 500) {
				toTop.classList.add('fixed-totop');
			} else {
				toTop.classList.remove('fixed-totop');
			}
		});
	}

	render() {
		return (
			<footer>
				
				{/* <!-- Widgets --> */}
				<div className="footer-widgets">
					<Container>
						
						<Row>
							
							{/* <!-- Footer logo --> */}
							<Col className="col-12 col-md-6 col-lg-3 res-margin">
								<div className="widget">
									<p className="footer-logo">
										<img src={LogoWhite} srcSet={`${LogoWhite2X} 2x`} alt="Naxos" />
									</p>
									<p>
									Discover delicious homemade dishes made with love, passion and care by an ever growing network of home based chef.
									</p>
									
									{/* <!-- Social links --> */}
									<div className="footer-social">
										<a href="/" aria-label="Facebook"><td role='presentation' onKeyDown={this.handleClick} onClick={()=> window.open("https://www.facebook.com/EatCoHome/", "Facebook")}><i className="fab fa-facebook"></i></td></a>
										<a href="/" aria-label="Instagram"><td role='presentation' onKeyDown={this.handleClick} onClick={()=> window.open("", "Instagram")}><i className="fab fa-instagram"></i></td></a>
										<a href="/" aria-label="Twitter"><td role='presentation' onKeyDown={this.handleClick} onClick={()=> window.open("", "Twitter")}><i className="fab fa-twitter fa-fw"></i></td></a>
										<a href="/" aria-label="linkedin"><td role='presentation' onKeyDown={this.handleClick} onClick={()=> window.open("https://www.linkedin.com/company/eatco-home/", "linkedin")}><i className="fab fa-linkedin"></i></td></a>
										<a href="/" aria-label="Youtube"><td role='presentation' onKeyDown={this.handleClick} onClick={()=> window.open("", "/")}></td><i className="fab fa-youtube"></i></a>
										<a href="/" aria-label="Pinterest"><td role='presentation'  onKeyDown={this.handleClick} onClick={()=> window.open("", "/")}><i className="fab fa-pinterest fa-fw"></i></td></a>
									</div>
									<div className='startup'>
									<p className='random'>Recognized by:</p>
										<p className='binomial'><img src={picture1} className="img-responsive img-rounded" alt="" /></p>
										
									</div>
								</div>
							</Col>
							
							{/* <!-- Useful links --> */}
							<Col className="col-12 col-md-6 col-lg-2 offset-lg-1 res-margin">
								<div className="widget">
									
									<h6>Useful Links</h6>
									
									<ul className="footer-menu">
										<li><a href="/#support">Support</a></li>
										<li><a href="/privacy-policy">Privacy Policy</a></li>
										<li><a href="/terms">Terms &amp; Conditions</a></li>
										{/* <li><a href="/">Affiliate Program</a></li> */}
										{/* <li><a href="/">Careers</a></li> */}
									</ul>
									
								</div>
							</Col>
							
							{/* <!-- Product help --> */}
							<Col className="col-12 col-md-6 col-lg-3 res-margin">
								<div className="widget">
									
									<h6>Product Help</h6>
									
									<ul className="footer-menu">
										<li><a href="/#support">FAQ</a></li>
										<li><a href="/#contact">Reviews</a></li>
										<li><a href="/#features">Features</a></li>
										<li><a href="/#contact">Feedback</a></li>
										{/* <li><a href="/">API</a></li> */}
									</ul>
									
								</div>
							</Col>
							
							{/* <!-- Download --> */}
							<Col className="col-12 col-md-6 col-lg-3">
								<div className="widget">
									
									<h6>Download</h6>
									
									<div className="button-store">
										<a href="https://play.google.com/store/apps/details?id=com.eatco.partnerapp" className="custom-btn d-inline-flex align-items-center m-2 m-sm-0 mb-sm-3"><i className="fab fa-google-play"></i><p>Available on<span>Google Play</span></p></a>
										<a href="/" className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"><i className="fab fa-apple"></i><p>Download on<span>App Store</span></p></a>
									</div>
									
								</div>
							</Col>
							
						</Row>
						
					</Container>
				</div>
				
				{/* <!-- Copyright --> */}
				<div className="footer-copyright">				
					<Container>
						
						<Row>						
							<Col className="col-12">
								
								{/* <!-- Text --> */}
								<p className="copyright text-center">
									Copyright © 2022 <a href="/" target="_blank">EatCo</a>. All Rights Reserved.
								</p>
								
							</Col>
						</Row>
						
					</Container>				
				</div>

			</footer>
		);
	}
	
}